import FeaturedList from "./FeaturedList";

function FeaturedArts({ arts }) {
  const featured = arts.filter((art) => art.featured);
  console.log(featured);
  return (
    <div className="container  highlight">
      <h2 className=" featured mb-3">Highlight pieces from the artist</h2>
      <div className="row row-cols-1 row-cols-md-4 g-4 ">
        {featured.map((featured) => (
          <FeaturedList featured={featured} key={featured.title} />
        ))}
      </div>
    </div>
  );
}

export default FeaturedArts;
