function CartItem() {
  return (
    <div className="row g-3">
      <div className=" col-md-6 col-lg-7  shop-cart">
        <div className="cart-items">
          <p className="img-cart ps-2 fw-bold">Item</p>

          <p className="img-qty fw-bold">Subtotal</p>
        </div>
        <div className="cart-shop-details">
          <div className="img-cart d-flex ">
            <div className="cart-image-art">
              <img src="../arts/cat.png" alt="title" />
            </div>
            <div className="cart-info-art">
              <p>The London Eye</p>
              <p>Serr Vohn</p>
              <p>Medium : Digital Photography</p>
            </div>
          </div>
          <div className="img-qty">$ 1200</div>

          <div className="img-x text-center">
            <i className="bi bi-trash3 fs-5"></i>
          </div>
        </div>
        <hr />
        <div className="cart-shop-details">
          <div className="img-cart d-flex ">
            <div className="cart-image-art">
              <img src="../arts/london.png" alt="title" />
            </div>
            <div className="cart-info-art">
              <p>The London Eye</p>
              <p>Serr Vohn</p>
              <p>Medium : Digital Photography</p>
            </div>
          </div>
          <div className="img-qty">$ 1200</div>

          <div className="img-x text-center">
            <i className="bi bi-trash3 fs-5"></i>
          </div>
        </div>
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5 col-lg-4 p-0">
        <div className="order-summary">
          <div className="cart-summary">
            <p>Order Summary</p>
          </div>
          <div className="subtotal">
            <p>Subtotal</p>
            <p>$ 1200</p>
          </div>
          <hr />
          <div className="subtotal">
            <p className="fw-bold">Order Total</p>
            <p className="fw-bold">$ 1200</p>
          </div>
          <div className="d-flex justify-content-center align-items-center cart-check-out">
            <p className="m-0">
              <i className="bi bi-bag-check"></i> CHECKOUT
            </p>
          </div>
          <p className="text-center">
            <i className="bi bi-shield-check"></i> Your Information is Safe
          </p>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
