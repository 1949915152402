import CartCategories from "./CartCategories";
import CartItem from "./CartItem";

function ShoppingCart() {
  return (
    <div className="container p-3">
      <h4 className="mb-5 mt-4 fw-bold">Your Shopping Cart</h4>
      <CartItem />
      <div className="my-5">
        <CartCategories />
      </div>
    </div>
  );
}

export default ShoppingCart;
