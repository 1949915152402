import { useEffect } from "react";
import { useFetcher } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselList from "./CarouselList";

function CartCategories() {
  const arts = useFetcher();

  useEffect(() => {
    if (!arts.data && arts.state === "idle") arts.load("/photography");
  }, [arts]);
  console.log(arts.data); //data of arts

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="container ">
      <h4 className="mb-3 fw-bold">Discover More</h4>
      <div className="row">
        <Slider {...settings}>
          {arts?.data?.length > 0 ? (
            arts.data
              .filter((art) => art.categories === "photography")
              .map((art) => <CarouselList arts={art} key={art.title} />)
          ) : (
            <p>No Image</p>
          )}
        </Slider>
      </div>
    </div>
  );
}

export default CartCategories;
