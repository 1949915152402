import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";

function ArtsList({ arts }) {
  const { currency } = useCurrency();
  const { setCount } = useCurrency();
  const [convertedPrice, setConvertedPrice] = useState(null);
  const [colorBookmark, setColorBookMark] = useState(false);
  const [bookMark, setBookMark] = useState(false);

  function handleCount() {
    setCount((c) => c + 1);
    setColorBookMark(true);
  }

  useEffect(() => {
    async function getConverted() {
      const convertedData = await getCurrency(arts.price, currency);
      setConvertedPrice(convertedData.rates[currency]);
    }

    getConverted();
  }, [currency]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  const { title, artist_name: artist, photos, id, categories } = arts;
  return (
    <div className="col-md-6 col-lg-3" key={id}>
      <div className="card">
        <div
          className="image-bookmark"
          onMouseEnter={() => setBookMark(true)}
          onMouseLeave={() => setBookMark(false)}
        >
          <Link to={`/${categories}/${id}`} onClick={scrollToTop}>
            <div className="img-resize">
              <img src={photos} alt="title" className="gallery-top" />
            </div>
          </Link>
          <div className={bookMark ? `heart-bookmark` : `hide`}>
            <i
              className={colorBookmark ? `bi bi-heart-fill` : `bi bi-heart`}
              onClick={handleCount}
            ></i>
          </div>
        </div>
        <div className="card-body">
          <p className="card-title fw-bold"> {title}</p>
          <p className="fs-6 artist-name"> {artist}</p>
          <p className="fw-bold mt-1">
            {currency === "GBP" && `Price : £ ${convertedPrice}`}
            {currency === "USD" && `Price : $ ${convertedPrice}`}
            {currency === "EUR" && `Price : € ${convertedPrice}`}
          </p>
          <div className="add-to-cart-item">
            <p>ADD TO CART</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArtsList;
