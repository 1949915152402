import { useLoaderData } from "react-router-dom";
import Hero from "./Hero";
import FeaturedArts from "./FeaturedArts";
import { getArts } from "../services/apiArts";
import FeaturedAuthor from "./FeaturedAuthor";
import Quote from "./Quote";
import Shipping from "./Shipping";

function Home() {
  const arts = useLoaderData();
  console.log(arts);
  return (
    <div>
      <Hero />
      <Quote />
      <FeaturedArts arts={arts} />
      <FeaturedAuthor />
      <Shipping />
    </div>
  );
}

export async function loader() {
  const arts = await getArts();
  return arts;
}
export default Home;
