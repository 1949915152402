import { Form, NavLink } from "react-router-dom";

function CreateAccount() {
  return (
    <div className="container mb-5 mt-4">
      <div className="row d-flex justify-content-center create-bg">
        <div className="col-md-6 col-lg-5 ">
          <h4 className="mt-5 fw-bold"> Create An Account</h4>
          <Form>
            <div className="mt-4">
              <p>Name</p>
              <div className="d-flex align-items-center form-login">
                <i className="bi bi-person-fill ms-1"></i>
                <input className="form-control" type="text" />
              </div>
            </div>
            <div>
              <p>Email</p>
              <div className="d-flex align-items-center form-login">
                <i className="bi bi-envelope-fill ms-1"></i>
                <input className="form-control" type="email" />
              </div>
            </div>
            <div>
              <p>Password</p>
              <div className="d-flex align-items-center form-login">
                <i className="bi bi-eye-fill ms-1"></i>
                <input className="form-control" type="password" />
              </div>
            </div>
            <div className="mt-4">
              <button className="btn btn-secondary w-25"> Register</button>
            </div>
          </Form>
          <p className="text-center mt-4">
            Already have an Account? <NavLink to="/login">Login </NavLink>
          </p>
        </div>
        <div className="col-md-6 col-lg-5">
          <div className="create-img">
            <img src="../heroImages/retro.png" alt="woman painting" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAccount;
