import ArtsList from "./ArtsList";

function ArtistArts({ featured, currentArtistId, artist }) {
  const artistArtsOnly = featured.filter((arts) => arts.artist_name === artist);
  const remainingArts = artistArtsOnly.filter(
    (arts) => arts.id !== currentArtistId
  );

  console.log(artistArtsOnly);
  if (remainingArts.length === 0) {
    return null;
  }
  return (
    <div className="container">
      <h2 className=" featured mb-5">More on {artist} Art's</h2>
      <div className="row row-cols-1 row-cols-md-4 g-4 d-flex justify-content-center ">
        {remainingArts.map((featured) => (
          <ArtsList arts={featured} key={featured.title} />
        ))}
      </div>
    </div>
  );
}

export default ArtistArts;
