import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home, { loader as HomeLoader } from "./components/Home";
import Galleries, { loader as galleryLoader } from "./components/Galleries";
import Login from "./components/Login";
import Contact from "./components/Contact";
import ArtsShop from "./components/ArtsShop";
import AppLayout from "./components/AppLayout";
import Error from "./components/Error";
import ArtsInfo, { loader as ArtsLoader } from "./components/ArtsInfo";
import FeaturedArts from "./components/FeaturedArts";
import Photography, {
  loader as PhotographyLoader,
} from "./components/Photography";
import Sculpture, { loader as SculptureLoader } from "./components/Sculpture";
import Sell, { action as SellAction } from "./components/Sell";
import { CostProvider } from "./components/CurrencyContext";
import ShoppingCart from "./components/ShoppingCart";
import CreateAccount from "./components/CreateAccount";

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: HomeLoader,
      },
      {
        path: "/paintings",
        element: <Galleries />,
        loader: galleryLoader,
        errorElement: <Error />,
      },
      {
        path: "/paintings/:itemId",
        element: <ArtsInfo />,
        loader: ArtsLoader,
        errorElement: <Error />,
      },

      {
        path: "/Login",
        element: <Login />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "gallery/art",
        element: <ArtsShop />,
      },
      {
        path: "/featuredArts",
        element: <FeaturedArts />,
      },
      {
        path: "/photography",
        element: <Photography />,
        loader: PhotographyLoader,
      },
      {
        path: "/photography/:itemId",
        element: <ArtsInfo />,
        loader: ArtsLoader,
        errorElement: <Error />,
      },
      {
        path: "/sculptures",
        element: <Sculpture />,
        loader: SculptureLoader,
      },
      {
        path: "/sculptures/:itemId",
        element: <ArtsInfo />,
        loader: ArtsLoader,
        errorElement: <Error />,
      },
      {
        path: "/sell",
        element: <Sell />,
        action: SellAction,
      },
      {
        path: "/cart",
        element: <ShoppingCart />,
      },
      {
        path: "/createaccount",
        element: <CreateAccount />,
      },
    ],
  },
]);

function App() {
  return (
    <CostProvider>
      <RouterProvider router={router} />;
    </CostProvider>
  );
}
export default App;
