import { useNavigate, useRouteError } from "react-router-dom";

function Error() {
  const navigate = useNavigate();
  const error = useRouteError();
  console.log(error);
  function handleHome() {
    navigate("/");
  }
  return (
    <div>
      <h1>Something went wrong {error.message}</h1>
      <button onClick={handleHome} className="btn btn-primary">
        Go back Home
      </button>
    </div>
  );
}

export default Error;
