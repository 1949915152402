import { useLoaderData } from "react-router-dom";
import { getArts } from "../services/apiArts";
import ArtsList from "./ArtsList";
import Shipping from "./Shipping";
import ArtistQuotes from "./ArtistQuotes";
import Sort from "./Sort";
import { useState } from "react";
import Banner from "./Banner";

function Photography() {
  const painting = useLoaderData();

  console.log(painting);
  const [sorted, setSorted] = useState("Latest");

  const Quote = "I am seeking. I am striving. I am in it with all my heart.";
  const Artist = "Vincent van Gogh";
  const BgImage = "banner-2";

  const photography = painting.filter(
    (paint) => paint.categories === "photography"
  );

  let sortedPhotography = [...photography];

  if (sorted === "high")
    sortedPhotography = photography.sort((a, b) => b.price - a.price);
  if (sorted === "low")
    sortedPhotography = photography.sort((a, b) => a.price - b.price);
  
  return (
    <div className="container">
      <Banner BgImage={BgImage} />
      <div className="quotes">
        <ArtistQuotes quote={Quote} artist={Artist} />
      </div>
      <div className=" sorts">
        <Sort sorted={sorted} setSorted={setSorted} />
      </div>
      <div className="row row-cols-1 row-cols-md-4 g-4 all-galleries">
        {sortedPhotography.map((arts) => (
          <ArtsList arts={arts} key={arts.title} />
        ))}
      </div>
      <Shipping />
    </div>
  );
}

export default Photography;

export async function loader() {
  const paintings = await getArts();
  return paintings;
}
