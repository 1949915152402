import { Form, NavLink } from "react-router-dom";
import CarouselLogins from "./CarouselLogins";

function Login() {
  return (
    <div className="container login-container my-5">
      <div className="row d-flex justify-content-center row-carousel">
        <div className="col-md-6 col-lg-5 carousel-column">
          <CarouselLogins />
        </div>
        <div className="col-md-6 col-lg-4 form-column ">
          <h4 className="my-3 text-center">Find What you Love Now</h4>

          <p className="text-center"> Welcome to FindArts</p>
          <Form className="mt-5 form-log">
            <div className="mb-3">
              <label htmlFor="username">
                Username <span>*</span>
              </label>
              <div className="form-login">
                <i className="bi bi-person-fill"></i>
                <input
                  type="text"
                  required
                  name="username"
                  className="form-control"
                />
              </div>
            </div>
            <div>
              <label htmlFor="password">
                Password <span>*</span>
              </label>
              <div className="form-login">
                <i className="bi bi-lock-fill"></i>
                <input
                  type="password"
                  required
                  name="password"
                  className="form-control"
                />
              </div>
            </div>
            <p className="text-end forgot-text">Forgot Password?</p>
            <div className="d-flex justify-content-center align-item-center mt-3 m-4">
              <button className="btn btn-secondary rounded-5 px-5">
                Sign in
              </button>
            </div>
          </Form>
          <p className="text-center">
            Don't have an account?
            <NavLink to="/createaccount">
              <span className="create-account ms-2">Create Account</span>
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
