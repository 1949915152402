import { useLoaderData } from "react-router-dom";
import { getArts } from "../services/apiArts";
import ArtsList from "./ArtsList";
import Shipping from "./Shipping";
import ArtistQuotes from "./ArtistQuotes";
import Sort from "./Sort";
import { useState } from "react";

function Sculpture() {
  const arts = useLoaderData();
  console.log(arts);
  const [sorted, setSorted] = useState("Latest");
  const sculptures = arts.filter(
    (sculpture) => sculpture.categories === "sculptures"
  );

  let SortedScupltures = [...sculptures];

  // if (sorted === "Latest") SortedScupltures = sculptures;
  if (sorted === "low")
    SortedScupltures = sculptures.sort((a, b) => a.price - b.price);
  if (sorted === "high")
    SortedScupltures = sculptures.sort((a, b) => b.price - a.price);
  console.log(sculptures);
  const Quote =
    "The true work of art is but a shadow of the divine perfection.";
  const Artist = "Michelangelo";
  return (
    <div className="container">
      <div className="quotes">
        <ArtistQuotes quote={Quote} artist={Artist} />
      </div>
      <div className="sorts">
        <Sort sorted={sorted} setSorted={setSorted} />
      </div>
      <div className="row row-cols-1 row-cols-md-4 g-4 all-galleries ">
        {SortedScupltures.map((arts) => (
          <ArtsList arts={arts} key={arts.title} />
        ))}
      </div>
      <Shipping />
    </div>
  );
}

export default Sculpture;

export async function loader() {
  const arts = await getArts();
  return arts;
}
