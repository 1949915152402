import AuthorBio from "./AuthorBio";
import { getDetailsArt } from "../services/apiArts";
import { useFetcher, useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import ArtistArts from "./ArtistArts";
import Shipping from "./Shipping";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";

function ArtsInfo() {
  const arts = useLoaderData();
  const { currency } = useCurrency();
  const [convertedPrice, setConvertedPrice] = useState(null);

  useEffect(() => {
    async function getConverted() {
      const convertedData = await getCurrency(arts.price, currency);
      setConvertedPrice(convertedData.rates[currency]);
    }

    getConverted();
  }, [currency]);

  const fetcher = useFetcher();

  useEffect(
    function () {
      if (!fetcher.data && fetcher.state === "idle")
        // another properties na pwede nya malaman status
        //if wala pa data get the data.
        fetcher.load("/photography"); //name of the route you want to get the data
    },
    [fetcher]
  );

  const {
    title,
    artist_name: artist,
    featured_artist: artistImage,
    photos,
    date_of_creation: dateCreated,
    description,
    medium,
    size,
    quotes,
    artist_profile,
    id,
  } = arts;

  return (
    <div className="container mt-5 p-3">
      <h2 className="featured mb-3"> Artwork Details</h2>
      <div className="row">
        <div className=" col-md-12 col-lg-6 art-img">
          <div className="art-img-size">
            <img src={photos} alt={title} />
          </div>
        </div>
        <div className=" col-md-12 col-lg-6  art-info">
          <div className="col">
            <div className="mt-5 ">
              <h4 className="fw-bold">{title}</h4>
              <p className="fw-medium">{artist}</p>
              <p>
                {" "}
                <span className="fw-bold"> Medium</span>: {medium}
              </p>
              <p>
                <span className="fw-bold">Date Created</span> : {dateCreated}{" "}
              </p>
              <p>
                <span className="fw-bold">Size</span>: {size}
              </p>
              <h5 className="fw-bold">About the Art Piece</h5>
              <p> {description}</p>
            </div>
            <div className="d-flex justify-content-center  row p-2">
              <div className="mt-2 art-price col-md-12">
                <h4>
                  {currency === "GBP" && `Price : £ ${convertedPrice}`}
                  {currency === "USD" && `Price : $ ${convertedPrice}`}
                  {currency === "EUR" && `Price : € ${convertedPrice}`}
                </h4>
                <button className="btn btn-secondary">
                  <i className="bi bi-cart2 me-1"></i> Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <AuthorBio
          featuredArtist={artistImage}
          quote={quotes}
          artist={artist}
          profile={artist_profile}
        />
      </div>
      <div className="more-on-arts">
        <ArtistArts
          featured={fetcher?.data ?? []}
          currentArtistId={id}
          artist={artist}
        />
      </div>

      <div className="my-5">
        <Shipping />
      </div>
    </div>
  );
}

export async function loader({ params }) {
  const arts = await getDetailsArt(params.itemId);
  console.log(arts);
  return arts;
}

export default ArtsInfo;
