import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";

function FeaturedList({ featured }) {
  const { currency } = useCurrency();
  const [convertedPrice, setConvertedPrice] = useState(null);

  useEffect(() => {
    async function getConverted() {
      const convertedData = await getCurrency(featured.price, currency);
      setConvertedPrice(convertedData.rates[currency]);
    }

    getConverted();
  }, [currency]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const {
    title,
    artist_name: artist,
    featured_artist: artistImage,
    photos,
    categories,
    id,
  } = featured;

  const [imageTransition, setImageTransition] = useState(false);

  return (
    <div className="col-md-6 col-lg-3" key={id}>
      <div className="card">
        <Link to={`/${categories}/${id}`} onClick={scrollToTop}>
          <div className="img-resize">
            <img
              onMouseEnter={() => setImageTransition(true)}
              onMouseLeave={() => setImageTransition(false)}
              src={imageTransition ? artistImage : photos}
              alt={title}
              className="card-img-top"
            />
          </div>
        </Link>
        <div className="card-body">
          <p className="card-title">Title : {title}</p>
          <p>Artist : {artist}</p>
          <p>
            {currency === "GBP" && `Price : £ ${convertedPrice}`}
            {currency === "USD" && `Price : $ ${convertedPrice}`}
            {currency === "EUR" && `Price : € ${convertedPrice}`}
          </p>
        </div>
      </div>
    </div>
  );
}

export default FeaturedList;
