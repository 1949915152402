const API_ARTS = "http://localhost:8000/arts";

//FETCH API
export async function getArts() {
  const res = await fetch(`${API_ARTS}`);
  if (!res.ok) throw Error("Failed of Getting Arts");
  const data = await res.json();
  return data;
}

//POST METHOD
export async function getItems(newItems) {
  try {
    const res = await fetch(`${API_ARTS}`, {
      method: "POST",
      body: JSON.stringify(newItems),
      mode: "cors",
    });
    if (!res.ok) throw Error();
    const data = await res.json();
    return data;
  } catch {
    throw Error("Failed to create selling Item");
  }
}

//FETCH API WITH ID
export async function getDetailsArt(id) {
  const res = await fetch(`${API_ARTS}/${id}`);
  if (!res.ok) throw Error(`Couldn't find Arts Details ${id}`);
  const data = await res.json();
  return data;
}
