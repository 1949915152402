import { Form, redirect, useNavigation } from "react-router-dom";
import { getItems } from "../services/apiArts";
import Shipping from "./Shipping";
import { useRef, useState } from "react";

function Sell() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  const fileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const artistInput = useRef(null);
  const [artistPhoto, setArtistPhoto] = useState(null);

  function handleArtistChange() {
    const file = artistInput.current.files[0];
    if (file && file.type.startsWith("image/")) {
      setArtistPhoto(file);
    } else {
      setArtistPhoto(null);
    }
  }

  function handleToArtist() {
    artistInput.current.click();
  }

  function handleRemoveArtist() {
    setArtistPhoto(null);
    artistInput.current.value = "";
  }

  function handleFileChange() {
    const file = fileInput.current.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  }

  function handleToUpload() {
    fileInput.current.click();
  }

  function handleRemoveImage() {
    setSelectedFile(null);
    fileInput.current.value = "";
  }

  return (
    <div className="container p-5 container-form">
      <div className="container label">
        <Form method="POST">
          <h4 className="artist-text">About The Art</h4>
          <div className="art-background">
            <div className="art-div">
              <div className="row-bot img-upload">
                <input
                  type="file"
                  id="image"
                  name="photos"
                  accept="image/*"
                  ref={fileInput}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div>
                  <label htmlFor="photos " className="col label-img">
                    <div
                      className={!selectedFile ? "img-view" : ""}
                      onClick={handleToUpload}
                    >
                      {!selectedFile && (
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="label-upload-img">
                            <div className="ms-3">
                              <i className="bi bi-upload fs-3"></i>
                            </div>
                            <div className="mx-3">
                              <p className="mt-3">Upload Image</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={selectedFile ? "img-view" : ""}>
                      {selectedFile && (
                        <div className="img-container">
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Uploaded"
                          />

                          <i
                            className="bi bi-x fs-1"
                            onClick={handleRemoveImage}
                          ></i>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <div className="row title pb-2 row-bot">
                <label htmlFor="title" className="col-md-6 ">
                  Title
                </label>
                <div className="input-bot col-md-6">
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className=" gagi"
                    placeholder="e.g. The Dawn"
                    required
                  />
                </div>
              </div>
              <div className="row row-bot">
                <label htmlFor="description" className="col-md-6">
                  Description
                </label>
                <div className="input-bot col-md-6">
                  <textarea
                    type="text"
                    id="description"
                    name="description"
                    rows="4"
                    className="rowsss"
                    placeholder="e.g.  Through a diverse range of initiatives, including workshops.. "
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row  mt-3 row-bot">
            <label htmlFor="categories" className="col-md-6">
              Categories
            </label>
            <div className="input-bot col-md-6">
              <select
                id="categories"
                name="categories"
                className="selectArea"
                required
              >
                <option value="sculptures">Sculpture</option>
                <option value="paintings">Paintings</option>
                <option value="photography">Photography</option>
              </select>
            </div>
          </div>
          <div className="row  row-bot">
            <label htmlFor="medium" className="col-md-6">
              Medium
            </label>
            <div className="col-md-6 input-bot">
              <input
                required
                type="text"
                id="medium"
                name="medium"
                placeholder="e.g. Acrylic, Woodcut, Digital etc., "
                className="selectArea"
              />
            </div>
          </div>
          <div className="row  row-bot">
            <label htmlFor="size" className="col-md-6 ">
              Size
            </label>
            <div className="input-bot col-md-6">
              <input
                required
                type="text"
                id="size"
                name="size"
                placeholder="e.g. 20' x 40'"
              />
            </div>
          </div>
          <div className="row  row-bot">
            <label htmlFor="date_of_creation" className="col-md-6">
              Date Created
            </label>
            <div className="col-md-6">
              <input
                required
                type="date"
                id="date_of_creation"
                name="date_of_creation"
              />
            </div>
          </div>
          <div className="row  row-bot">
            <label htmlFor="price" className="col-md-6">
              Price
            </label>
            <div className="col-md-6 input-bot">
              <input
                type="text"
                id="price"
                name="price"
                placeholder="£0.00"
                required
              />
            </div>
          </div>

          <hr />
          <h4 className="artist-text">About The Artist</h4>
          <div className=" row row-bot">
            <label htmlFor="artist_name" className="col-md-6">
              Artist Name
            </label>
            <div className="col-md-6 input-bot">
              <input
                type="text"
                id="artist_name"
                name="artist_name"
                placeholder="e.g. Vincent"
              />
            </div>
          </div>
          <div className="row row-bot">
            <label htmlFor="artist_profile" className="col-md-6">
              Profile
            </label>
            <div className="col-md-6 input-bot">
              <textarea
                type="text"
                id="artist_profile"
                name="artist_profile"
                className="rowsss"
                placeholder="e.g. He began his career as an art dealer before deciding to pursue painting full-time in his late twenties. His early works were characterized by dark, somber tones and depicted scenes of rural life and labor."
                rows="4"
              />
            </div>
          </div>
          <div className="row  row-bot">
            <label htmlFor="quotes" className="col-md-6">
              Quotations
            </label>
            <div className="col-md-6 input-bot">
              <input
                type="text"
                id="quotes"
                name="quotes"
                placeholder="Creativity knows no bounds"
                className="rowsss"
              />
            </div>
          </div>
          <div className="row row-bot artist-section ">
            <div className="col-md-6 ">
              <label htmlFor="featured_artist ">Artist Image</label>
            </div>

            <div className="col-md-6 artist-solo-img">
              <input
                type="file"
                id="featured_artist"
                name="featured_artist"
                accept="image/*"
                required
                style={{ display: "none" }}
                onChange={handleArtistChange}
                ref={artistInput}
              />
              <div
                className={!artistPhoto ? "artist-box" : ""}
                onClick={handleToArtist}
              >
                {!artistPhoto && (
                  <div className="d-flex solo-artist">
                    <div className="ms-1 mt-2">
                      <i className="bi bi-upload fs-4  "></i>
                    </div>
                    <div className="mx-1">
                      <p className="mt-3">Upload</p>
                    </div>
                  </div>
                )}
              </div>
              <div className={artistPhoto ? "artist-box" : ""}>
                {artistPhoto && (
                  <div className="artist-image-container">
                    <img src={URL.createObjectURL(artistPhoto)} alt="Artist" />
                    <i className="bi bi-x " onClick={handleRemoveArtist}></i>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-end mt-4">
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "UPLOADING...." : "UPLOAD"}
            </button>
          </div>
        </Form>
      </div>
      <div className="mt-5">
        <Shipping />
      </div>
    </div>
  );
}

export async function action({ request }) {
  //react router-dom application
  const formItems = await request.formData(); //html method
  const data = Object.fromEntries(formItems); //data that I sent
  console.log(data); //show data I sent
  const artistImage = formItems.get("featured_artist"); // name of image (kiel.png)
  console.log(artistImage); // kiel.png
  const artImage = formItems.get("photos"); //kiel.png
  const artImageUrl = `../arts/${artImage}`; //manipulate the path of json.arts
  data.photos = artImageUrl;

  const imageUrl = `../arts/${artistImage}`;
  data.featured_artist = imageUrl;
  const newItems = await getItems(data); //my function post method

  // const newData = { ...newItems, featured_artist: imageUrl };
  // const imageUrl = `../arts/${data.featured_artist}`;
  // const updatedData = { ...newItems, imageUrl };
  return redirect(`/${newItems.categories}`);
}
export default Sell;
