import { useLoaderData } from "react-router-dom";
import { getArts } from "../services/apiArts";
import ArtsList from "./ArtsList";
import Shipping from "./Shipping";
import ArtistQuotes from "./ArtistQuotes";
import Sort from "./Sort";
import { useState } from "react";
import Banner from "./Banner";

function Galleries() {
  const arts = useLoaderData();
  console.log(arts);
  const [sorted, setSorted] = useState("Latest");

  const paintings = arts.filter((paint) => paint.categories === "paintings");

  const Quote =
    "Art enables us to find ourselves and lose ourselves at the same time.";
  const Artist = "Thomas Merton";
  const BgImage = "banner";

  let sortedPaintings = [...paintings];

  if (sorted === "low")
    sortedPaintings = paintings.sort((a, b) => a.price - b.price);
  if (sorted === "high")
    sortedPaintings = paintings.sort((a, b) => b.price - a.price);

  return (
    <div className="container">
      <Banner BgImage={BgImage} />
      <div className="quotes">
        <ArtistQuotes quote={Quote} artist={Artist} />
      </div>
      <div className="sorts">
        <Sort sorted={sorted} setSorted={setSorted} />
      </div>
      <div className="row row-cols-1 row-cols-md-4 g-4 all-galleries ">
        {sortedPaintings.map((arts) => (
          <ArtsList arts={arts} key={arts.title} />
        ))}
      </div>
      <Shipping />
    </div>
  );
}

export async function loader() {
  const arts = await getArts();
  return arts;
}

export default Galleries;
