import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useCurrency } from "./CurrencyContext";

function NavBar() {
  const { currency, setCurrency } = useCurrency();
  const { count } = useCurrency();

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container-fluid nav-bg sticky-top">
      <div className="container navbar-expand-lg navbar d-flex align-items-center">
        <NavLink
          to="/"
          onClick={scrollToTop}
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        >
          <img src="../logo.svg" alt="FindArts Logo" className="logo" />
        </NavLink>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <NavLink
              to="/"
              end
              onClick={scrollToTop}
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis "
            >
              <img src="../logo.svg" alt="Logo" className="logo" />
            </NavLink>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  to="/"
                  className="nav-link mx-lg-2"
                  aria-current="page"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/paintings"
                  onClick={scrollToTop}
                  className="nav-link mx-lg-2"
                >
                  Paintings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/photography"
                  onClick={scrollToTop}
                  className="nav-link mx-lg-2 "
                >
                  Photography
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/sculptures"
                  onClick={scrollToTop}
                  className="nav-link mx-lg-2"
                >
                  Sculpture
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/sell"
                  className="nav-link mx-lg-2"
                  onClick={scrollToTop}
                >
                  Sell
                </NavLink>
              </li>
            </ul>
            {/* <div className="nav-item d-flex align-items-center  me-2">
              <NavLink to="/Login" onClick={scrollToTop}>
                <Contact />
              </NavLink>
            </div> */}
            <div className="nav-link mx-lg-2 currency  me-2">
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="GBP">GBP</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>

            <div className="add-to-cart mt-1 nav-item me-3">
              <div className="mt-1 nav-item like-container">
                <p>
                  <i class="bi bi-heart"></i>
                </p>
                <div className="like-item">
                  <span>{count}</span>
                </div>
              </div>
            </div>
            <NavLink to="/cart">
              <div className="add-to-cart mt-1 nav-item me-2">
                <div className="mt-1 nav-item like-container">
                  <p>
                    <i class="bi bi-cart2"></i>
                  </p>
                  <div className="like-item">
                    <span>{count}</span>
                  </div>
                </div>
              </div>
            </NavLink>
            <NavLink
              to="/Login"
              onClick={scrollToTop}
              className="nav-link mx-lg-2"
            >
              <div className="nav-item">
                <div className="add-to-cart">
                  <p>
                    <i className="bi bi-box-arrow-in-right"></i> Login
                  </p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  );
}

export default NavBar;
