import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="container-fluid footer-bg">
        <div className="container footer-container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <img src="../logo.svg" alt="Logo" className="logo" />
              <p>
                Explore, experience, and find inspiration as you journey through
                our diverse selection of artworks. Let your imagination soar and
                join us on a transformative artistic adventure today
              </p>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 address">
              <h3>OFFICE</h3>
              <p>102 Tremona Road</p>
              <p>SO16 6HT</p>
              <p>93 Laundry Road</p>
              <p>Southampton, United Kingdom</p>
              <p>Tel: 073 123 456</p>
              <p className="email">findarts@gmail.com</p>
            </div>
            <div className="col-sm-3 col-md-4 col-lg-2">
              <h3>LINKS</h3>
              <ul className="service">
                <li>
                  <NavLink to="/" className="text-decoration-none text-black">
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/paintings"
                    className="text-decoration-none text-black"
                  >
                    Paintings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/photography"
                    className="text-decoration-none text-black"
                  >
                    Photography
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="sculptures"
                    className="text-decoration-none text-black"
                  >
                    Sculpture
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/sell"
                    className="text-decoration-none text-black"
                  >
                    Sell
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-sm-5 col-md-4 col-lg-3">
              <h3>NEWSLETTER</h3>
              <form className="form-footer">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="form-control"
                />
                <button type="submit" className="btn btn-secondary">
                  <i className="bi bi-arrow-right"></i>
                </button>
              </form>
              <div className="footer-social">
                <i className="bi bi-facebook"></i>
                <i className="bi bi-twitter-x"></i>
                <i className="bi bi-instagram"></i>
                <i className="bi bi-pinterest"></i>
              </div>
            </div>
          </div>
          <hr />
          <div className="copyright">
            <p className="text-center fw-medium">
              FindArts &#169; 2024 - All Rights Reserved!
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
